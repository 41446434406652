import React, { useState } from "react";
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const TableOfContents = ({ headings }) => {
  const serialize = s => s.replace(/\s+/g, '-').toLowerCase();

  const [active, setActive] = useState(serialize(headings[0].value));

  const closest = n => xs => xs.reduce((a, b) => {
      return Math.abs(b[1] - n) < Math.abs(a[1] - n) ? b : a;
  });

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const domHeadings =
        headings
          .filter(h => h.depth < 3)
          .map(h => document.getElementById(serialize(h.value)));
      const heights = domHeadings.map(a => [a.id, a.getBoundingClientRect().top])
      setActive(closest(0)(heights)[0])
    },
    [active]
  )

  return (
    <aside className="toc">
      <ul>
        <h3>Table of contents</h3>
        <hr/>
        { headings.map(({value, depth}, i) =>
          {
            const serialized = serialize(value);
            const cn = serialized === active ? "active" : ""
            return (
              depth < 3 && <li key={i}><a className={cn} href={"#" + serialized}>{value}</a></li>
            )
          }
        )}
      </ul>
    </aside>
  )
}

export default TableOfContents;
