import React from "react"
import { Link, graphql } from "gatsby"
import { DiscussionEmbed } from 'disqus-react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import TableOfContents from "../components/table-of-contents"
import { MDXRenderer } from 'gatsby-plugin-mdx'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const disqusConfig = {
    shortname: "epeery",
    config: { identifier: post.fields.slug, siteTitle },
  }

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <div>
          <article>
            <header style={{textAlign: "center"}}>
              <h3><span>{post.frontmatter.date}</span></h3>
              <h1>{post.frontmatter.title}</h1>
              <blockquote>
                <p>
                  <em>{post.frontmatter.description}</em>
                </p>
              </blockquote>
            </header>
            <section>
              <TableOfContents headings={post.headings}/>
              <div className="post-body">
                <MDXRenderer>{post.body}</MDXRenderer>
                <hr/>
              </div>
            </section>
          </article>
        </div>
      </Layout>
      <DiscussionEmbed {...disqusConfig} />
    </>
  )
}


// <nav>
//   <ul>
//     <li>
//       {previous && (
//         <Link to={previous.fields.slug} rel="prev">
//           <span className="prev-post">{previous.frontmatter.title}</span>
//         </Link>
//       )}
//     </li>
//     <li>
//       {console.log(previous, next)}
//       { next && (
//         <Link to={next.fields.slug} rel="next">
//           <span className="next-post">{next.frontmatter.title}</span>
//         </Link>
//       )}
//     </li>
//   </ul>
// </nav>

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      headings {
        depth
        value
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`
